import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { rem } from "polished";
import {
  Heading,
  Span,
  Div,
  Dropdown,
  DropdownOnChange,
  StationButton,
  WaitingButton,
} from "~/components";
import { useGetDiagnosisListQuery } from "~/services/diagnosis";
import { styled } from "~/stitches.config";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useDispatch, useSelector } from "react-redux";
import chetstate from "../../public/icon_cs@3x.png";
import { api } from "~/woozooapi";

const Flex = styled("div", {
  display: "flex",
  alignItems: "center",
  ".page-name": {
    fontSize: "$s3-20",
    fontWeight: "bold",
    color: "$blacks",
  },
  ".sorted-btn button": {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontSize: "$p5-13",
    color: "&gray01",
    fontWeight: "normal",
    padding: 0,
    "&:hover, &:focus": {
      fontWeight: "bold",
    },
  },
});
interface Props {
  lastestSortedHandler: () => void;
  pastSortedHandler: () => void;
  onChange: DropdownOnChange;
}

const DIAGNOSIS_STATUS_OPTIONS_DOCTOR = [
  { label: "전체", value: "all" },
  { label: "대기", value: "waiting" },
  { label: "진료중", value: "ongoing" },
  { label: "취소", value: "canceled" },
];

export const SortAndFilter: React.FC<Props> = ({ ...props }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isStation = router.pathname.includes("/station");
  const [chatData, setChatData] = useState<any>("")

  const { data: waitingListInfo } = useGetDiagnosisListQuery(
    {},
    {
      pollingInterval: 10000,
    }
  );

  useEffect(() => {
    api.diagnosis.getNoticeList().then((res: any) => setChatData(res))
  }, [])


  return (
    <Flex css={{ flexDirection: "column", marginTop: "19px" }}>
      <Flex
        css={{
          justifyContent: "space-between",
          marginTop: "19px",
          width: "100%",
        }}
      >
        <Flex css={{ gap: rem(7) }}>
          <Heading className="page-name">
            {isStation ? "스테이션" : "대기실"}{" "}
          </Heading>
          {!isStation ? (
            <>
              <Flex css={{ alignItems: "end" }}>
                <Span
                  css={{
                    fontSize: "$s5-18",
                    fontWeight: "bold",
                    color: "$primary",
                    lineHeight: "1.4",
                  }}
                >
                  {waitingListInfo?.totalWaiting ?? 0}
                </Span>
                <Span
                  css={{
                    color: "$gray04",
                    marginLeft: rem(4),
                  }}
                >
                  / {waitingListInfo?.count}건
                </Span>
              </Flex>
            </>
          ) : null}
        </Flex>
        <Flex css={{ gap: rem(8) }}>
          <WaitingButton />
          <StationButton />
          {!isStation && (
            <Div
              css={{
                ".react-select__control": {
                  fontSize: rem(14),
                  minHeight: rem(44),
                  backgroundColor: "$white",
                },
                ".react-select__option": {
                  fontSize: rem(14),
                  paddingLeft: rem(24),
                },
              }}
            >
              <Dropdown
                id="selectDiagnosisStatus"
                aria-label="진료 상태 선택"
                onChange={props.onChange}
                options={DIAGNOSIS_STATUS_OPTIONS_DOCTOR}
                defaultValue={{ label: "전체", value: "all" }}
              />
            </Div>
          )}
        </Flex>
      </Flex>
      {
        waitingListInfo?.isAnnouncement === false ?
          chatData && chatData.results.map((data: any, index: number) =>
          (<div style={{
            width: "100%",
            height: 34,
            margin: "10px 0 0",
            padding: "0px 1rem 0px 0.5rem",
            borderRadius: "20px",
            backgroundColor: "#ececec",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            letterSpacing: "-0.36px",
          }} key={index}>
            <div style={{ display: "flex" }}>
              <div>
                {data.icon ? <img src={data.icon} width={rem(24)} height={rem(20)} alt="icon" /> : null}
              </div>
              <div style={{ color: "rgba(0, 0, 0, 0.6)", paddingLeft: "10px" }}>
                {data.title}
              </div>
            </div>
            {index === 0 ? <div style={{ color: "#000", borderBottom: "1px solid", cursor: "pointer" }}>
              {/* <a href="http://pf.kakao.com/_xkKbfb">채널톡 문의 남기기</a> */}
              <div onClick={() => window.open('http://pf.kakao.com/_xkKbfb', '_blank')}>채널톡 문의 남기기</div>
            </div> : null}
          </div>)
          )
          :
          // <div style={{
          //   width: "100%",
          //   height: 34,
          //   margin: "25px 0 0",
          //   padding: "0px 2vw 0px 1vw",
          //   borderRadius: "20px",
          //   backgroundColor: "#ececec",
          //   display: "flex",
          //   justifyContent: "space-between",
          //   alignItems: "center",
          //   letterSpacing: "-0.36px",
          // }}>
          //   <div style={{ display: "flex" }}>
          //     <SupportAgentIcon />
          //     <div style={{ color: "rgba(0, 0, 0, 0.6)", paddingLeft: "10px" }}>
          //       <span>문의사항은 고객센터 <span style={{ fontWeight: "bold" }}>02-522-7706</span> 으로 전화 주세요.</span>
          //     </div>
          //   </div>
          //   <div style={{ color: "#000", borderBottom: "1px solid", cursor: "pointer" }}>
          //     <a href="http://pf.kakao.com/_xkKbfb">채널톡 문의 남기기</a>
          //   </div>
          // </div>
          chatData && chatData.results.map((data: any, index: number) =>
          (<div style={{
            width: "100%",
            height: 34,
            margin: "10px 0 0",
            padding: "0px 1rem 0px 0.5rem",
            borderRadius: "20px",
            backgroundColor: "#ececec",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            letterSpacing: "-0.36px",
          }} key={index}>
            <div style={{ display: "flex" }}>
              {data.icon ? <img src={data.icon} width="24px" height="20px" alt="icon" /> : null}
              <div style={{ color: "rgba(0, 0, 0, 0.6)", paddingLeft: "10px" }}>
                {data.title}
              </div>
            </div>
            {index === 0 ?
              <div style={{ color: "#000", borderBottom: "1px solid", cursor: "pointer" }}>
                <div onClick={() => window.open('http://pf.kakao.com/_xkKbfb', '_blank')}>채널톡 문의 남기기</div>
              </div> : null}
          </div>)
          )
      }
    </Flex >
  );
};
