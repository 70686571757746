import { useRouter } from "next/router";
import { rem } from "polished";
import { MouseEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  RegisterFormRowItem,
  RoundedButton,
  FormRowItemWrapper,
  HospitalImageUploadList,
  RegisterFormSection,
  Label,
  Div,
  Input,
  Span,
  FormFieldErrorMessage,
  PlaceHolder,
} from "~/components";
import { api } from "~/woozooapi";

export const HospitalInfoForm = () => {
  const { register, getValues, setValue, trigger, formState } =
    useFormContext();
  const router = useRouter();
  const [hospitalImages, setHospitalImages] = useState<
    { src: string; alt: string }[]
  >([]);

  useEffect(() => {
    const inputValues = getValues("hospitalPics");
    if (inputValues) {
      setHospitalImages(
        getValues("hospitalPics")?.map((url: string, index: number) => ({
          src: url,
          alt: `hospital-image-${index}`,
        }))
      );
    }
  }, [formState, getValues]);

  const handleHospitalRegisterClick = (e: MouseEvent) => {
    e.preventDefault();

    router.push(
      {
        pathname: "/auth/register/[...info]",
        query: {
          ...router.query,
          info: ["hospital"],
        },
      },
      undefined,
      { scroll: false }
    );
  };

  const handleHospitalImageUpload = async (files: File[]) => {
    const response = await api.fileUpload({
      file: files[0],
      name: "hospital-image",
    });

    const url = response.url;

    const prev = getValues("hospitalPics");
    const next = prev ? [...prev, url] : [url];
    setValue("hospitalPics", next);
    await trigger("hospitalPics");
  };

  const handleHospitalPicDelete = async (index: number) => {
    const next = hospitalImages
      .filter((_, i) => i !== index)
      ?.map((img) => img.src);
    setValue("hospitalPics", next);
    await trigger("hospitalPics");
  };

  return (
    <RegisterFormSection
      css={{
        "input[disabled]": {
          border: "1px solid $gray06",
          backgroundColor: "$gray08",
        },
      }}
    >
      <h2>소속 병원</h2>

      <FormRowItemWrapper>
        <Label htmlFor="register-hospital" required>
          병원 등록
        </Label>
        <RoundedButton
          color="orange"
          outlined
          css={{ height: rem(50) }}
          onClick={handleHospitalRegisterClick}
        >
          병원 등록
        </RoundedButton>
        <Input type="hidden" {...register("hospitalRegister")} />
        {formState.errors?.hospitalRegister?.message && (
          <FormFieldErrorMessage
            css={{ gridArea: "message", marginTop: rem(6) }}
          >
            {formState.errors?.hospitalRegister?.message}
          </FormFieldErrorMessage>
        )}
      </FormRowItemWrapper>
      <Input type="hidden" {...register("hospital")} />
      <RegisterFormRowItem
        id="hospitalName"
        label="병원 이름"
        type="text"
        hide={!Boolean(getValues("hospitalName"))}
        required
        disabled
        {...register("hospitalName")}
      />
      <RegisterFormRowItem
        id="hospitalTel"
        label="전화번호"
        type="tel"
        required
        disabled
        hide={!Boolean(getValues("hospitalTel"))}
        {...register("hospitalTel")}
      />
      {Boolean(getValues("hospitalZonecode")) && (
        <FormRowItemWrapper
          css={{
            alignItems: "flex-start",
          }}
        >
          <Label htmlFor="hospitalAddress1" required>
            주소
          </Label>
          <Div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: rem(10),
              input: { width: "100%" },
            }}
          >
            <Input disabled {...register("hospitalAddress1")} />
            <Input disabled {...register("hospitalAddress2")} />
          </Div>
        </FormRowItemWrapper>
      )}
      <FormRowItemWrapper
        css={{
          label: {
            gridColumn: "1/4",
          },
          "& > div": {
            gridColumn: "1/4",
          },
        }}
      >
        <label htmlFor="hospitalPics">
          병원 사진{" "}
          <Span
            css={{
              marginLeft: rem(6),
              color: "$gray04",
              data: { fontWeight: "bold", color: "$primary" },
            }}
          >
            <data>{hospitalImages.length}</data> / 10
          </Span>
        </label>
        <PlaceHolder css={{ gridColumn: "1/4" }}>
          고객에게 보여질 병원 사진을 등록해주세요.
        </PlaceHolder>
        <Input type="hidden" {...register("hospitalPics")} />
        <HospitalImageUploadList
          handleFiles={handleHospitalImageUpload}
          onDeleteClick={handleHospitalPicDelete}
          images={hospitalImages}
        />
      </FormRowItemWrapper>
    </RegisterFormSection>
  );
};
