import { useRouter } from "next/router";
import { rem } from "polished";
import { FC } from "react";
import { useSelector } from "react-redux";
import {
  Div,
  ProfilePic,
  RoundedButton,
  DoctorApprovalStatusButton,
  SettingsSection,
  SettingsSectionContent,
  SettingsSectionHeader,
  SettingsEditButton,
  SpeciallistBadge,
  PasswordEditButton,
} from "~/components";
import { useGetDoctorInfoQuery } from "~/services/doctor";
import { selectDoctorEditState } from "~/store/settingsSlice";

export const DoctorInfoSection: FC = () => {
  const router = useRouter();
  const { data } = useGetDoctorInfoQuery();
  const doctorEditState = useSelector(selectDoctorEditState);
  const edit = doctorEditState === "edit";

  

  return (
    <SettingsSection>
      <SettingsSectionHeader headerText="기본 정보" />
      <SettingsSectionContent css={{ display: "flex" }}>
        <Div
          css={{
            width: rem(164),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRight: "1px solid $gray08",
          }}
        >
          <Div css={{ height: rem(20) }} />
          <ProfilePic src={data?.hospitalUser.image ?? ""} />
          <Div css={{ height: rem(20) }} />
          {edit ? (
            <SettingsEditButton
              onClick={() => {
                router.push("/settings/edit/image", undefined, {
                  scroll: false,
                });
              }}
            />
          ) : (
            <DoctorApprovalStatusButton
              status={data?.hospitalUser.approvalStatus ?? "standby"}
              onClick={() => {
                router.push({
                  pathname: "/settings/edit/image",
                });
              }}
            />
          )}
        </Div>
        <Div
          css={{
            flex: rem(370),
            display: "flex",
            flexDirection: "column",
            paddingLeft: rem(30),
            paddingRight: rem(30),
          }}
        >
          <Div css={{ height: rem(26) }} />
          <Div
            css={{
              display: "flex",
              alignItems: "center",
              h3: {
                margin: 0,
                fontSize: rem(20),
                fontWeight: "bold",
                marginRight: rem(20),
              },
            }}
          >
            <h3>{data?.hospitalUser.username}</h3>
            {!edit && data?.hospitalUser.specialist ? (
              <SpeciallistBadge>
                {data.hospitalUser.specialist}
              </SpeciallistBadge>
            ) : (
              <RoundedButton
                css={{
                  width: rem(124),
                  height: rem(40),
                  border: "none",
                  backgroundColor: "$fileInputBgColor",
                  color: "$primary",
                }}
                onClick={() => {
                  router.push("/settings/edit/special", undefined, {
                    scroll: false,
                  });
                }}
              >
                전문의 등록
              </RoundedButton>
            )}
          </Div>
          <Div css={{ height: rem(20) }} />
          <Div css={{ display: "flex", fontSize: rem(17) }}>
            {data?.hospitalUser.email}
          </Div>
          <Div css={{ height: rem(14) }} />
          <Div
            css={{ display: "flex", alignItems: "center", fontSize: rem(17) }}
          >
            {data?.hospitalUser.mobile}
            <SettingsEditButton
              css={{ marginLeft: rem(30) }}
              onClick={() => {
                router.push("/settings/edit/mobile", undefined, {
                  scroll: false,
                });
              }}
            />
          </Div>
          <Div css={{ height: rem(20) }} />
          <PasswordEditButton
            onClick={() => {
              router.push("/settings/edit/password", undefined, {
                scroll: false,
              });
            }}
          />
          <Div css={{ height: rem(30) }} />
        </Div>
      </SettingsSectionContent>
    </SettingsSection>
  );
};
